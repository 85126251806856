.card {
  font-family: Montserrat;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 7px 24px 0px rgba(0, 0, 0, 0.08);
  padding: 20px;
  margin: 20px;
  /* display: flex; */
  align-items: center;
  width: 100%;
}

.tileIcon {
  color: #3f76bf;
  margin-right: 10px;
}
.tileHeading {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  height: 50px;
}

.tileCount {
  display: flex;
  font-size: 26px;
}
.tilesContainer {
  display: flex;
  font-size: 16px;
  align-items: center;
}

.chartContainer {
  height: 200px;
}

.chartsWrapper {
  /* gap: 20px;
  margin-left: 20px;
  margin-right: 20px; */
}
