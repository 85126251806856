.textInput {
  background: transparent;
  border: none;
  height: 50px;
  width: 20em;
  color: white !important;
  border: 1px solid transparent;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 40px;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  outline: none;
  font-family: "Montserrat";
  font-size: 17px;
  margin-bottom: 10px;
  /* margin-top: 10px; */
}

.textInput :focus {
  color: #0000 !important;
}
.formGroup {
  padding: 5px;
}

.showPassword {
  text-align: left;
  padding: 5px;
}
.buttonSubmit {
  background: #fbceb5 !important;
  border: 1px solid #fbceb5 !important;
  border-radius: 40px;
  height: 50px;
  padding: 10px;
  font-family: "Montserrat";
  color: #000 !important;
  margin-top: 20px;
  width: 200px;
}

.fieldIcon {
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.9);
}

.formWrapper {
  text-align: center;
}

.formGroupActions {
  display: flex;
  text-align: center;
  margin-top: 20px;
  color: #fff;
}

.checkBoxPrimary {
  color: #fbceb5;
}

.checkBoxPrimary input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

input[type="checkbox"] {
  accent-color: #fbceb5;
}
