.loginCardWrapper {
  /* background-color: red; */
  font-family: "Montserrat";
}

.loginCard {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  width: 25em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  text-align: center;
  padding: 10px;
  border-radius: 20px;
}

.loginUserMessage {
  margin-top: 30px;
  color: #fff;
  font-weight: 400;
}

.signInOptionsWrapper {
  margin-top: 20px;
  display: flex;
}

.signInOtherOptionButton {
  display: block;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 40px;
  color: #000;
  background: #fff;
  padding: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.signInOtherOptionButton:hover {
  width: 100%;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: #000;
  color: #fff;
  margin-left: 5px;
  margin-right: 5px;
}

.iconMargStylesAdjust {
  margin-top: 3px;
}

.signUpUserMessage {
  margin-top: 30px;
  color: #fff;
  font-weight: 400;
  padding: 10px;
  background-color: #8ea9c4;
  border-radius: 10px;
}
