.formCard {
  background-color: #ffffff;
  backdrop-filter: blur(5px);
  /* width: 100em; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #dedede;
}
.formStyles {
  display: flex;
}
.fieldStyles {
  display: block;
  padding: 10px;
}
.labelStyles {
  font-family: "Montserrat";
  margin-left: 5px;
  margin-bottom: 5px;
  color: #979797;
}
.textInput {
  background: #fff;
  border: none;
  height: 50px;
  width: 99%;
  color: #000 !important;
  border: 1px solid #dedede;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-family: "Montserrat";
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.textAreaInput {
  background: #fff;
  border: none;
  height: 80px;
  width: 99%;
  color: #000 !important;
  border: 1px solid #dedede;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-family: "Montserrat";
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.textInput:focus {
  outline: none;
  box-shadow: 0 0 0 1px #2a9d8f;
}

.textAreaInput:focus {
  outline: none;
  box-shadow: 0 0 0 1px #2a9d8f;
}

.buttonContainer {
  display: flex;
  align-items: center;
  text-align: end;
}

.buttonStyles {
  border: 1px solid #2a9d8f;
  border-radius: 10px;
  font-size: 15px;
  background-color: #fff;
  padding: 10px 20px 10px 20px;
  color: #2a9d8f;
  margin-left: auto;
}

.buttonStyles:hover {
  border: 1px solid #2a9d8f;
  background-color: #2a9d8f;
  color: #fff;
}

.errorMessage {
  color: #ba181b !important;
}
