.test {
  border: 2px solid black;
  background-color: red;
}

.manuSideCard {
  background-color: #f0efeb;
  border-radius: 10px;
  padding: 10px;
}

.companyInfo {
  display: flex;
  padding: 5px;
}
.companyInfoSub {
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  width: 800px;
  /* max-width: 200px; */
}

.iconContainer {
}
.IconStyles {
  color: blue;
}
.smallText {
  color: black;
  font-size: 14px;
  margin-left: 5px;
}

.simpleCard {
  background-color: #f0efeb;
  border-radius: 10px;
  padding: 10px;
}
/* ========================================================== */
.imgDiv {
  /* height: 100px;
  width: 100px; */
  /* margin-left: auto;
  margin-right: auto;
  text-align: center; */

  height: auto;
  display: block;
  position: absolute;
  top: 0%; /* Move the image up by half of its height */
  left: 50%;
  transform: translateY(-50%);
}

.companyLogo {
  /* height: 70px;
  width: 70px; */
  border-radius: 20px;
  /* object-fit: cover; */
}

.profileCard {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 7px 24px 0px rgba(0, 0, 0, 0.08);
  padding: 20px;
  margin: 20px;
  position: relative;
  margin-top: 50px;
  display: grid;
}

.profileHeader {
  margin-top: 20px;
}

.companyTitle {
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.companySubTitle {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ratingsWrapper {
  margin-left: auto;
  margin-top: 5px;
}
.rating {
  color: #3f76bf;
}
.profileButtonContainer {
  display: flex;
}
.profileButton {
  padding: 8px 16px;
  background-color: #3f76bf;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 14px;
  font-family: Montserrat;
}
.card {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 7px 24px 0px rgba(0, 0, 0, 0.08);
  padding: 20px;
  margin: 20px;
}

.title {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.contentWrapper {
  display: flex;
  margin-top: 4px;
  align-items: center;
  font-family: Montserrat;
}

.locationIcon {
  color: #3f76bf;
  font-family: Montserrat;
}
.address {
  color: var(--Text, #001d32);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
  text-transform: capitalize;
  margin-left: 3px;
  font-family: Montserrat;
}
