.container {
}

.defaultText {
  font-family: Montserrat;
  font-size: 20px;
  margin-left: 40px;
  margin-top: 30px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 7px 24px 0px rgba(0, 0, 0, 0.08);
  padding: 20px;
  margin: 20px;
  display: flex;
  align-items: center;
}
.connectIcon {
  color: #3f76bf;
  margin-right: 10px;
}
