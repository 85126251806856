.card {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 7px 24px 0px rgba(0, 0, 0, 0.08);
  padding: 20px;
  margin: 20px;
}
.rating {
  color: #3f76bf;
}

.title {
  display: flex;
  align-items: center;
  font-family: Montserrat;
}
.name {
  margin-right: 20px;
  color: var(--Text, #001d32);
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 96% */
  letter-spacing: 0.5px;
}
.ratingsWrapper {
  margin-left: auto;
  margin-top: 5px;
}
.locationWrapper {
  display: flex;
  margin-top: 4px;
  align-items: center;
  font-family: Montserrat;
}

.locationIcon {
  color: #3f76bf;
  font-family: Montserrat;
}
.address {
  color: var(--Text, #001d32);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
  text-transform: capitalize;
  margin-left: 3px;
  font-family: Montserrat;
}
.description {
  color: var(--Text, #001d32);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}

.services {
  margin-top: 10px;
  display: flex;
}
.keyWordsButton {
  padding: 2px 12px;
  background-color: #3f76bf;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.buttonContainer {
  margin-left: auto;
}

.viewMoreButton {
  padding: 8px 16px;
  background-color: #3f76bf;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin-left: 20px;
  font-family: Montserrat;
}
