/* Table.module.css */

.table-container {
  width: 100%;
  overflow-x: auto;
  border-radius: 10px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 20px;
}

.th,
.td {
  padding: 15px 12px;
  /* border: 1px solid #ddd; */
}

.th {
  background-color: #3f76bf;
  font-weight: bold;
  text-align: left;
  color: #fff;
}

.tr:nth-child(even) {
  background-color: #f2f2f2;
}

.tr:hover {
  background-color: #ddd;
}
