.searchField {
  background-color: #ffffff;
  outline: none;
  display: flex;
  width: 630px;
  height: 45px;
  padding: 0px 20px;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  border: 1px solid #79747e;
  background: #fff;
  font-size: 16px;
  transition: border-color 0.3s;
  color: #79747e;
  font-family: Montserrat;
}

:focus {
  border-color: #3f76bf; /* Change the border color when focused */
  outline: none; /* Remove the default focus outline */
}
.searchButton {
  padding: 8px 16px 8px 16px;
  background-color: #3f76bf;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin-left: 20px;
  font-family: Montserrat;
  font-weight: 500;
}

.searchWrapper {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  font-family: Montserrat;
}

.keywordsWrapper {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  font-family: Montserrat;
}
.searchContainer {
  display: flex;
}

.keyWordsButton {
  padding: 5px 12px 5px 12px;
  background-color: #3f76bf;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 16px;
  font-family: Montserrat;
}

.closeIcon {
  margin-top: 4px;
  margin-left: 3px;
}
